import React, {useRef, useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import FormContainer from '../Forms/FormContainer';
import {theme} from '../../themes/default-theme';
import {Text} from '../../components/typography';
import dlPush from '../../util/dlPush';
import ReactHtmlParser from 'react-html-parser';

const Container = styled.div`
  padding: 20px 15px;
  background: white;

  ${theme.breakpoints.up('md')} {
    padding: 60px 30px;
    background: ${theme.palette.skyGradientLight};
    border-bottom-left-radius: 0px;
  }

  ${theme.breakpoints.up('lg')} {
    padding: 60px 80px;
  }

  [role="radiogroup"] {
    label {
      margin-right: 23px;

      ${theme.breakpoints.up('md')} {
        margin-right: 53px;
      }
    }
  }

  textarea {
    min-height: 160px;
  }
`

const Wrapper = styled.div`
  background: ${theme.palette.skyGradientLight};
  padding: 20px 15px;
  margin-left: -15px;
  margin-right: -15px;

  ${theme.breakpoints.up('md')} {
    background: transparent;
    padding: 0;
    padding-left: 30px;
  }
`

const Title = styled(Text)`
  text-align: left;
  ${theme.breakpoints.up('md')} {
    text-align: left;
  }
`

const ThankYou = styled.div`

`

const Address = styled(Text)`

`

const StockEnquiryForm = ({
  modelId,
  dealerId,
  dealerName,
  dealerAddress,
  title
}) => {

  const [thankYou, showThankYou] = useState(false);

  const handleSubmitted = () => {
    dlPush({
      event: 'jaycoEvent',
      eventAction: 'Form Submit Success',
      eventCategory: 'Engage',
      eventLabel: 'Make an Enquiry'
    });
    showThankYou(true);
    const { top } = wrapperRef.current.getBoundingClientRect();
    const yOffset = -100;
    const position = top + window.pageYOffset + yOffset
    position ? window.scrollTo({
      top: position,
      behavior: 'smooth'
    }) : wrapperRef.current.scrollIntoView({ behavior: 'smooth'});
  }
  const dealer = process.browser && process.env.DEALER_SITE ? window.staticly.dealerData.name : "{{data.template.dealer.name}}"
  const dealerTitle = dealerName ? dealerName : dealer
  
  const wrapperRef = useRef();

  const dealerField = dealerId ? {
    type:  'hidden',
    name: 'dealer',
    value: dealerId
  } : {}

  return (
    <Wrapper ref={wrapperRef}>
      <Container>
        {thankYou ? 
        <ThankYou>
          <Title>Your enquiry has been received. A representative from {dealerTitle} will be in contact with you soon.</Title>
        </ThankYou>
        :
        <>
          <Title variant="h6" colour="black">{title}</Title>
          {dealerAddress && 
            <Address variant="body1" colour="black">
              {ReactHtmlParser(`
                ${dealerAddress.addressLine1},<br />
                ${dealerAddress.addressLine2.length > 0 ? `${dealerAddress.addressLine2},<br />` : ''}
                ${dealerAddress.state} ${dealerAddress.postcode}
              `)}
            </Address>
          }
          <FormContainer
              onThankyou={() => handleSubmitted()}
              formId="stock_enquiry"
              padding={false}
              background="transparent"
              topo={false}
              thankYouUrl="inline"
              formItems={[
                {...dealerField},
                {
                  type:  'hidden',
                  name: 'model',
                  value: modelId
                },
                {
                  type: 'name',
                  name: 'first_name',
                  label: 'first_name',
                  placeholder: 'First name*',
                  required: true,
                  errorText: 'This field is required',
                },
                {
                  type: 'name',
                  name: 'last_name',
                  label: 'last_name',
                  placeholder: 'Last name*',
                  required: true,
                  errorText: 'This field is required',
                },
                {
                  type: 'email',
                  name: 'email',
                  label: 'email',
                  placeholder: 'Email*',
                  required: true,
                  errorText: 'Please add a valid email address',
                },
                {
                  type: 'phone',
                  name: 'phone',
                  label: 'phone',
                  placeholder: 'Contact number*',
                  required: true,
                  errorText: 'Please add a valid phone number',
                },
                {
                  type: 'radio',
                  name: 'trade_in',
                  label: `Do you have a van to trade in?*`,
                  showLabel: true,
                  inline: true,
                  options: [{
                    optionValue: 'Yes',
                    label: 'Yes',
                  },{
                    optionValue: 'No',
                    label: 'No',
                  }],
                  required: true,
                  // Conditional for fileupload
                  onVisibilityIndex: [7, 'Yes'],
                  errorText: 'Please select yes or no',
                },
                {
                  type: 'fileupload',
                  name: 'files[]',
                  label: 'fileupload',
                  placeholder: 'Upload any supporting photos (optional)',
                  required: false,
                  // Visibility update, handled by trade_in radio
                  visible: false,
                  errorText: 'Please select a valid file type',
                },
                {
                  type: 'textarea',
                  name: 'comments',
                  label: 'comments',
                  placeholder: 'Comments',
                  required: false,
                },
                {
                  type: 'checkbox',
                  margin: 'none',
                  name: 'e_newsletter',
                  label: `Sign me up for ${dealerTitle}'s e-Newsletter`,
                },
                {
                  type: 'checkbox',
                  margin: 'none',
                  name: 'privacy',
                  label: `I have read and agree to ${dealerTitle}'s <a href="/privacy" target="_blank">Privacy&nbsp;Policy</a>*`,
                  required: true,
                  errorText: 'This field is required',
                }
              ]}
            />
          </>
        }
      </Container>
    </Wrapper>
  )
}
  
StockEnquiryForm.propTypes = {
  modelId: propTypes.string.isRequired,
  dealerId: propTypes.string,
  dealerName: propTypes.string,
  title: propTypes.string,
  dealerAddress: propTypes.object
}
  
StockEnquiryForm.defaultProps = {
  modelId: null,
  dealerId: null,
  dealerName: null,
  title: 'Make an enquiry',
  dealerAddress: null
}
  
export default StockEnquiryForm